<template>
  <UserProfile
      v-if="selected&&user"
      :can-create-learner="
      !!$store.getters['entities/user-permissions/find']('create learners')
    "
      :can-select-wards="false"
      :canCreate="
      !!$store.getters['entities/user-permissions/find']('create learners')
    "
      :canCreateEnrolment="
      !!$store.getters['entities/user-permissions/find']('create enrolments')
    "
      :canCreateExtramural="
      !!$store.getters['entities/user-permissions/find']('create extramurals')
    "
      :canCreateGuardian="
      !!$store.getters['entities/user-permissions/find']('edit learners')
    "
      :canCreateSubject="
      !!$store.getters['entities/user-permissions/find']('create subjects')
    "
      :canCreateWard="
      !!$store.getters['entities/user-permissions/find']('edit guardians')
    "
      :canDelete="
      !!$store.getters['entities/user-permissions/find']('delete learners')
    "
      :canDeleteEnrolment="
      !!$store.getters['entities/user-permissions/find']('delete enrolments')
    "
      :canDeleteExtramural="
      !!$store.getters['entities/user-permissions/find']('delete extramurals')
    "
      :canDeleteGuardian="
      !!$store.getters['entities/user-permissions/find']('edit learners')
    "
      :canDeleteSubject="
      !!$store.getters['entities/user-permissions/find']('delete subjects')
    "
      :canDeleteWard="
      !!$store.getters['entities/user-permissions/find']('edit guardians')
    "
      :canEdit="
      !!$store.getters['entities/user-permissions/find']('edit learners')
    "
      :canEditEnrolment="
      !!$store.getters['entities/user-permissions/find']('edit enrolments')
    "
      :canEditExtramural="
      !!$store.getters['entities/user-permissions/find']('edit extramurals')
    "
      :canEditGuardian="
      !!$store.getters['entities/user-permissions/find']('edit learners')
    "
      :canEditServices="
      !!$store.getters['entities/user-permissions/find']('edit wards users services')
    "
      :canEditSubject="
      !!$store.getters['entities/user-permissions/find']('edit subjects')
    " :canEditWard="
      !!$store.getters['entities/user-permissions/find']('edit guardians')
    "
      :guardian-mode="true"
      :profile-fields="[
      'date_of_birth',
      'id',
      'gender',
      'language',
      'grade',
      'dominant_hand',
      'ethnicity',
      'medical_information',
      'siblings',
      'consent','lunch','day_length',
      'emergency_contact',
      'home_address','school'
    ]"
      :propped-user="user"
      :show-campus="false"
      :showCampuses="false"
      :showGuardianVisibility="false"
      :showRoles="false"
      :tabs="dynamicTabs"
      :type="'Learner'"
      @deleted="handleDelete"
  ></UserProfile>
</template>

<script>
import UserProfile from "@/components/users/UserProfile";
import User from "@/models/User";
import Enrolment from "@/models/Enrolment";

export default {
  props: ["id"],

  components: {
    UserProfile,
  },
  data() {
    return {
      selected: false,
      hasEnrolment: false,
    };
  },

  computed: {
    user() {
      return User.query().where('id', parseInt(this.id)).first()
    },

    dynamicTabs() {
      if (this.hasEnrolment) {
        return [
          {title: 'User Details', role: 'details'},
          {title: 'Profile', role: 'profile'},
          {title: 'Services', role: 'services'},
          ...(this.$store.getters['entities/user-permissions/find']('view reports') ? [{
            title: 'Reports',
            role: 'reports'
          }] : []),
          ...(this.$store.getters['entities/user-permissions/find']('view media') ? [{
            title: 'Media',
            role: 'media'
          }] : []),
          {title: 'Incidents', role: 'incidents'}

        ]
      }
      return [
        {title: 'User Details', role: 'details'},
        {title: 'Profile', role: 'profile'},
        ...(this.$store.getters['entities/user-permissions/find']('view reports') ? [{
          title: 'Reports',
          role: 'reports'
        }] : []),
        ...(this.$store.getters['entities/user-permissions/find']('view media') ? [{
          title: 'Media',
          role: 'media'
        }] : []), {title: 'Incidents', role: 'incidents'}]

    }

  },
  methods: {
    handleDelete() {
      this.$router.push("/learners");
    },
    mountActions() {
      Enrolment.deleteAll()
      Enrolment.FetchAll({page: 1, limit: 1}, {user_id: parseInt(this.id)}).then(() => {
        if (Enrolment.query().where('user_id', parseInt(this.id)).count() > 0) {
          this.hasEnrolment = true
        }
        this.selected = true
      })

    },
  },
  async mounted() {
    await User.FetchById(parseInt(this.$store.state.user.id), ['wards']).then(response => {
      if (response.response.data.data.attributes.wards.length > 0) {
        this.selected = true
        console.log(response)
      } else {
        this.$router.push('/g/')
      }
    }).catch(err => {
      this.handleError(err)
    });
    this.mountActions()

  },
};
</script>
